<template>
  <div>
    <form v-on:submit.prevent="save" class="column max">
      <b-field label="Identifier" v-if="!isNew" horizontal>{{ batch.id }}</b-field>

      <b-field
        label="Date"
        v-if="batch.date"
        horizontal
      >{{ batch.date | moment('YYYY.MM.DD') }}</b-field>

      <b-field
        label="Updated at"
        v-if="batch.updated_at"
        horizontal
      >{{ batch.updated_at | moment('YYYY.MM.DD H:mm:ss') }}</b-field>

      <b-field label="Product name" horizontal>
        <b-input v-if="isNew" v-model="batch.product_name" required type="text"></b-input>
        <span v-else>{{ batch.product_name }}</span>
      </b-field>

      <b-field label="hardware_revision" horizontal>
        <b-input v-if="isNew" v-model="batch.hardware_revision" type="text"></b-input>
        <span v-else>{{ batch.hardware_revision }}</span>
      </b-field>

      <b-field label="Assembly variant" horizontal>
        <b-input v-if="isNew" v-model="batch.assembly_variant" type="text"></b-input>
        <span v-else>{{ batch.assembly_variant }}</span>
      </b-field>

      <b-field label="Manufacturer" horizontal>
        <b-input v-if="isNew" v-model="batch.manufacturer" type="text"></b-input>
        <span v-else>{{ batch.manufacturer }}</span>
      </b-field>

      <b-field label="Quantity (pcs)" horizontal>
        <b-input v-if="isNew" v-model="batch.quantity" type="text"></b-input>
        <span v-else>{{ batch.quantity }}</span>
      </b-field>

      <b-field label="Serial number START" v-if="!isNew" horizontal>
        {{ batch.serial_number_start }}
      </b-field>

      <b-field label="Serial number STOP" v-if="!isNew" horizontal>
        {{ batch.serial_number_stop }}
      </b-field>

      <b-field label horizontal>
        <div class="buttons">
          <b-button
            v-if="isNew"
            type="is-success"
            icon-left="plus-box"
            native-type="submit"
            :disabled="isFetching"
          >Add Batch</b-button>
          <template v-else-if="!isEdit">
            <!-- <b-button
              type="is-info"
              icon-left="pencil"
              :disabled="isFetching"
              v-on:click="isEdit = true"
            >Edit Batch</b-button> -->
          </template>
          <template v-else>
            <b-button
              type="is-info"
              icon-left="close"
              :disabled="isFetching"
              v-on:click="closeEdit"
            >Close</b-button>
            <b-button
              type="is-success"
              icon-left="content-save"
              native-type="submit"
              :disabled="isFetching"
            >Update</b-button>
          </template>
        </div>
      </b-field>
    </form>

    <b-field label horizontal v-if="batch.id">
      <batch-statistics :batch_id="batch.id" :cache="statistics" />
    </b-field>
  </div>
</template>

<script>
export default {
  name: 'batch',
  data() {
    return {
      isEdit: false,
      isFetching: false,
      batch: {},
    };
  },
  created() {
    if (this.isNew) {
      this.isEdit = true;
    } else if (this.$route.params.id) {
      this.fetch();
    }
  },
  computed: {
    isNew() {
      return this.$route.name === 'batch-add';
    },
  },
  methods: {
    async fetch() {
      this.isFetching = true;
      const { data } = await this.$http.get(`api/v1/batch/${this.$route.params.id}`);
      if (data.billing === undefined) data.billing = {};
      this.batch = data;
      this.isFetching = false;
    },
    async save() {
      if (this.isNew) {
        this.isFetching = true;
        try {
          const { data } = await this.$http.post('api/v1/batch', this.batch);
          this.batch = data;
          this.$router.push({ name: 'batch', params: { id: data.id } });
        } catch (error) {
          console.log(error);
        }
      }
      this.isEdit = false;
      this.isFetching = false;
    },
    closeEdit() {
      this.isEdit = false;
      this.fetch();
    },
  },
};
</script>
